import React, { FC, useState } from "react";
import { ImageCarouselProps, ImageCarouselSlide } from "./../generated-types";
import { Splide, SplideSlide, SplideTrack, SplideProps } from "@splidejs/react-splide"
import { Splide as SplideItem } from "@splidejs/splide"
import { ImageCarouselStyling } from "./styles";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Box } from "@chakra-ui/react";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { mediaApi, useSitecoreContext } from "~/foundation/Jss";
import { CaptionFooter } from "../CaptionFooter";
import { CursorLarge } from "~/foundation/Components/Cursor/CursorLarge";
import { GenericHeader } from "~/foundation/Components/GenericHeader";
import { withDatasourceCheck } from "@sitecore-jss/sitecore-jss-react";
import { imageSizes, themable } from "~/foundation/Theme";
import { LazyImage } from "~/foundation/Components/Image/LazyImage";

// Setting the breakpoints according to design
const imgSizeBreakpoints = {
	wide: {
		width: "313px",
		height: "235px",
		mt: "calc(313px - 235px)"
	},
	tall: {
		width: "235px",
		height: "313px",
		mt: "0px"
	},
	square: {
		width: "274px",
		height: "274px",
		mt: "calc(313px - 274px)"
	}
};

const getImageFormat = (slideItem: ImageCarouselSlide): ImageFormatType | null => {
	const imgHeight = parseInt(slideItem.source?.value?.height!);
	const imgWidth = parseInt(slideItem.source?.value?.width!);

	if (isNaN(imgHeight) || isNaN(imgWidth)) {
		return null;
	}

	if (imgHeight === imgWidth) {
		return "square";
	}

	if (imgHeight > imgWidth) {
		return "tall";
	}

	return "wide";
};


type ImageFormatType = keyof typeof imgSizeBreakpoints;

type SlideObject = {
	slide: ImageCarouselSlide;
	imageFormat: ImageFormatType;
}

const ImageCarousel: FC<ImageCarouselProps> = ({ fields, rendering }) => {
	if (!fields) {
		console.warn("ImageCarousel is missing a dataSource")
		return <></>;
	}

	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;

	const slides: SlideObject[] = (rendering.customData?.slides || []).map(slide => ({
		slide,
		imageFormat: getImageFormat(slide) ?? "square"
	}));

	const imageFormats = slides.map(x => x.imageFormat);

	const splideProps: SplideProps = {
		options: {
			lazyLoad: "nearby",
			pagination: false,
			snap: true,
			autoplay: false,
			type: "slide",
			autoWidth: true,
			autoHeight: true,
			gap: "1rem",
			cover: false,
			arrows: false,
			flickPower: 75,
			focus: 0,
			waitForTransition: true,
			direction: isRtl ? "rtl" : "ltr",
			perPage: 1,
			mediaQuery: 'min',
			breakpoints: {
				700: {
					gap: "1.25rem",
					perPage: 10,
				},
			}
		}
	};

	const [customCursor, setCustomCursor] = useState(false);
	const [cursorPosition, setCursorPosition] = useState({
		x: 0,
		y: 0
	});

	const carouselEnter = (e: React.MouseEvent) => {
		setCursorPosition({
			x: e.clientX,
			y: e.clientY,
		});
		setCustomCursor(true);
	}

	const carouselLeave = () => setCustomCursor(false);

	const updateProgress = (splide: SplideItem, index: number) => {
		const totalItems = splide.Components.Controller.getEnd();
		const progressBar = splide.root.querySelector(".splide__progressbar span") as HTMLSpanElement;
		const rate = Math.min((index + 1) / (totalItems + 1), 1);
		progressBar.style.width = `${(100 * rate)}%`;
	}

	//image margin top is dependent on image format but also the tallest image in the entire slider.
	const getImageMargin = (imageHeight: string): string => {
		const maxHeight = imageFormats.includes("tall") ? imgSizeBreakpoints.tall.height : imageFormats.includes("square") ? imgSizeBreakpoints.square.height : imgSizeBreakpoints.wide.height;
		const imageMargin = `${parseInt(maxHeight) - parseInt(imageHeight)}px`;

		return imageMargin;
	}

	//footer Caption width is dependent on image width
	const getFooterCaptionWidth = (format: ImageFormatType) => {
		const footerCaptionWidth = format === "tall" ? imgSizeBreakpoints.tall.width : format === "square" ? imgSizeBreakpoints.square.width : imgSizeBreakpoints.wide.width;

		return footerCaptionWidth;
	}

	// Getting the actual image ration on a wider scale (wide, tall, square) and returning the correct proportions based on that
	const getRatio = (slideItem: ImageCarouselSlide) => {
		const imgHeight = parseInt(slideItem.source?.value?.height!);
		const imgWidth = parseInt(slideItem.source?.value?.width!);

		const slideProportions = {
			width: imgSizeBreakpoints.wide.width,
			height: imgSizeBreakpoints.wide.height,
			mt: imgSizeBreakpoints.wide.mt,
		};

		slideProportions.width =
			imgHeight > imgWidth
				? imgSizeBreakpoints.tall.width
				: (imgHeight === imgWidth
					? imgSizeBreakpoints.square.width
					: imgSizeBreakpoints.wide.width);


		slideProportions.height =
			imgHeight > imgWidth
				? imgSizeBreakpoints.tall.height
				: (imgHeight === imgWidth
					? imgSizeBreakpoints.square.height
					: imgSizeBreakpoints.wide.height);

		slideProportions.mt =
			imgHeight > imgWidth
				? getImageMargin(imgSizeBreakpoints.tall.height)
				: (imgHeight === imgWidth
					? getImageMargin(imgSizeBreakpoints.square.height)
					: getImageMargin(imgSizeBreakpoints.wide.height));

		return slideProportions;
	}

	return (
		<Ribbon py={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]} overflowX="hidden">
			<ContentWrapper py={["0", null]}>
				<GenericHeader {...fields}/>
			</ContentWrapper>
			<ContentWrapper py={["0", null]} px={['var(--chakra-sizes-pageMarginSM)', null, "8", null, '16']}>
				<CursorLarge {...cursorPosition} useCustomCursor={customCursor} />
				<Box {...ImageCarouselStyling}>
					<Splide
						{...splideProps}
						className="carouselElement"
						tag={"section"}
						onMove={updateProgress}
						onMounted={(splide) => updateProgress(splide, 0)}
						hasTrack={false}>
						<SplideTrack>
							{slides.map(({ slide, imageFormat }, index) => {

								if (!imageFormat) {
									return null;
								}

								const ratio = getRatio(slide);

								const trackingData = {
									id: rendering.dataSource,
									index,
									count: rendering.customData?.slides?.length ?? 0,
									url: slide.source!.value!.src!
								};

								const hasImg = !!slide.source?.value?.src;

								return (
									<SplideSlide key={index}>
										<Box
											onMouseEnter={carouselEnter}
											onMouseLeave={carouselLeave}
											cursor="grab"
											data-tracking-type="visibility"
											data-tracking-id="image_carousel"
											data-tracking-data={JSON.stringify(trackingData)}
											{...ratio}
											className="splide__slide__container"
											bgColor={hasImg ? "transparent" : "primary.aramcoGrey"}
										>
											{imageFormat === "tall" && (
												<LazyImage
													src={mediaApi.updateImageUrl(slide.source!.value!.src!, imageSizes.none)}
													sizes="235px"
													data-splide-lazy-srcset={mediaApi.getSrcSet(slide.source!.value!.src!, [imageSizes.imageCarousel.portrait.sm, imageSizes.imageCarousel.portrait.lg])}
													alt={slide.source!.value!.alt}
												/>
											)}
											{imageFormat === "wide" && (
												<LazyImage
													src={mediaApi.updateImageUrl(slide.source!.value!.src!, imageSizes.none)}
													sizes="313px"
													data-splide-lazy-srcset={mediaApi.getSrcSet(slide.source!.value!.src!, [imageSizes.imageCarousel.landscape.sm, imageSizes.imageCarousel.landscape.lg])}
													alt={slide.source!.value!.alt}
												/>
											)}
											{imageFormat === "square" && (
												<LazyImage
													src={mediaApi.updateImageUrl(slide.source!.value!.src!, imageSizes.none)}
													sizes="274px"
													data-splide-lazy-srcset={mediaApi.getSrcSet(slide.source!.value!.src!, [imageSizes.imageCarousel.square.sm, imageSizes.imageCarousel.square.lg])}
													alt={slide.source!.value!.alt}
												/>
											)}
										</Box>
										<CaptionFooter
											captionText={slide.captionText}
											captionLink={slide.captionLink}
											variant="secondary"
											customStyling={{ my: "2", pe: "8", maxWidth: getFooterCaptionWidth(imageFormat) }}
										/>
									</SplideSlide>
								);
							}).filter(Boolean)}
						</SplideTrack>
						<Box
							className="splide__progressbar"
							mt="2.625rem"
							w="100%"
							h="3px"
							borderRadius="2px"
							position="relative"
							bgColor="progressBar.default">
							<Box
								as="span"
								className="progressbar__animation"
								borderRadius="2px"
								h="100%"
								position="absolute"
								overflow="hidden">
								<Box
									w="100%"
									h="100%"
									borderRadius="2px"
									position="absolute"
									bg="gradients.brand"
								/>
							</Box>
						</Box>
					</Splide>
				</Box>
			</ContentWrapper>
		</Ribbon>
	);
};

export default withDatasourceCheck()(themable()(ImageCarousel));